<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="创建会员体系"> </el-page-header>
      </div>
    </div>
    <div class="speed">
      <div class="li" style="color: #2791ff">
        <img
          src="../../../../assets/images/activity/icon_current.png"
          v-if="speed === '基本信息'"
        />
        <img
          src="../../../../assets/images/activity/icon_carry_out.png"
          v-if="speed !== '基本信息'"
        />
        基本信息<i class="right"></i>
      </div>
      <div class="li" :style="speed !== '基本信息' ? 'color: #2791ff' : null">
        <img
          src="../../../../assets/images/activity/icon_not_selected.png"
          v-if="
            speed !== '会员等级设置' &&
            speed !== '积分获取规则' &&
            speed !== '积分抵用规则'
          "
        />
        <img
          src="../../../../assets/images/activity/icon_current.png"
          v-if="speed === '会员等级设置'"
        />
        <img
          src="../../../../assets/images/activity/icon_carry_out.png"
          v-if="speed === '积分获取规则' || speed === '积分抵用规则'"
        />
        会员等级设置
        <i class="right"></i>
      </div>
      <div
        class="li"
        :style="
          speed !== '基本信息' && speed !== '会员等级设置'
            ? 'color: #2791ff'
            : null
        "
      >
        <img
          src="../../../../assets/images/activity/icon_not_selected.png"
          v-if="speed !== '积分获取规则' && speed !== '积分抵用规则'"
        />
        <img
          src="../../../../assets/images/activity/icon_current.png"
          v-if="speed === '积分获取规则'"
        />
        <img
          src="../../../../assets/images/activity/icon_carry_out.png"
          v-if="speed === '积分抵用规则'"
        />
        积分获取规则
        <i class="right"></i>
      </div>
      <div
        class="li"
        :style="
          speed !== '基本信息' &&
          speed !== '会员等级设置' &&
          speed !== '积分获取规则'
            ? 'color: #2791ff'
            : null
        "
      >
        <img
          src="../../../../assets/images/activity/icon_not_selected.png"
          v-if="speed !== '积分抵用规则'"
        />
        <img
          src="../../../../assets/images/activity/icon_current.png"
          v-if="speed === '积分抵用规则'"
        />
        积分抵用规则
      </div>
    </div>
    <el-form
      :model="info"
      class="middle"
      label-width="170px"
      ref="info"
      :rules="rules"
    >
      <div
        v-if="speed === '基本信息'"
        class="content"
        style="
          padding-bottom: 15px;
          margin-top: 10px;
          border-radius: 7px;
          height: 80vh;
        "
      >
        <div class="title">
          <div class="span"></div>
          基本信息
        </div>
        <el-form-item label="会员体系名称" prop="title">
          <el-input
            clearable
            v-model="info.title"
            placeholder="请输入会员体系名称"
            style="width: 20%"
            maxlength="10"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            clearable
            v-model="info.remark"
            type="textarea"
            placeholder="请输入备注"
            style="width: 20%"
          />
        </el-form-item>
      </div>
      <div
        v-if="speed === '会员等级设置'"
        style="
          border-radius: 7px;
          padding: 0 20px 15px 20px;
          margin-top: 10px;
          background: white;
        "
      >
        <div class="title">
          <div class="span"></div>
          会员等级设置
        </div>
        <div class="card">
          <div class="left">
            <el-button type="primary" @click="handleAddLevel"
              >新增会员等级</el-button
            >
          </div>
        </div>
        <div class="table">
          <el-table
            ref="plTable"
            :data="client_system_level"
            key="goods_type_id"
            stripe
            height="320"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="200"
            ></el-table-column>
            <el-table-column label="等级图标" prop="level_image" align="center">
              <template slot-scope="scope">
                <img :src="scope.row.level_image" class="levelImg" />
              </template>
            </el-table-column>
            <el-table-column
              label="等级名称"
              prop="level_name"
              align="center"
            ></el-table-column>
            <el-table-column
              label="累计消费金额"
              prop="sales_amount"
              align="center"
            ></el-table-column>
            <el-table-column
              label="操作"
              align="center"
              prop="action"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    type="text"
                    @click="handleEditLevelInfo(scope.row, scope.$index)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    @click="handleDelLevelInfo(scope.row, scope.$index)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="speed === '积分获取规则'"
        style="
          border-radius: 7px;
          padding: 0 20px 15px 20px;
          margin-top: 10px;
          background: white;
        "
      >
        <div class="title">
          <div class="span"></div>
          积分获取规则
        </div>
        <div class="table" style="border-top: 1px solid #e8e8e8">
          <el-table :data="client_system_get_inegral" stripe height="520">
            <el-table-column
              label="品类"
              prop="goods_type_name"
              align="center"
            />
            <el-table-column label="积分获取规则" prop="action" align="center">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-select
                    style="margin: 5px 0"
                    placeholder="请选择获取方式"
                    v-model="scope.row.integral_type"
                  >
                    <el-option value="10" label="不设置"></el-option>
                    <el-option value="20" label="按每件货标签价"></el-option>
                    <el-option value="30" label="按每件货实付金额"></el-option>
                    <el-option value="40" label="按每件货总重"></el-option>
                    <el-option value="50" label="按每件货净金重"></el-option>
                  </el-select>
                  <div
                    v-if="
                      scope.row.integral_type &&
                      scope.row.integral_type !== '10'
                    "
                    style="margin-left: -60px"
                  >
                    每
                    <el-input
                      style="width: 20%; margin: 5px"
                      placeholder="请输入"
                      v-model="scope.row.integral_unit"
                    />
                    {{
                      (scope.row.integral_type &&
                        scope.row.integral_type === "40") ||
                      (scope.row.integral_type &&
                        scope.row.integral_type === "50")
                        ? "克"
                        : "元"
                    }}可获得
                    <el-input
                      style="width: 20%; margin: 5px"
                      v-model="scope.row.integral_proportion"
                      placeholder="请输入"
                    />
                    积分
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        v-if="speed === '积分抵用规则'"
        style="
          border-radius: 7px;
          padding: 0 20px 15px 20px;
          margin-top: 10px;
          background: white;
        "
      >
        <div class="title">
          <div class="span"></div>
          积分抵用规则
        </div>
        <el-form-item label="积分使用汇率">
          <div style="display: flex">
            每
            <el-form-item>
              <el-input
                v-model="info.use_integral_unit"
                placeholder="请输入"
                style="width: 90%; margin: 0 5px"
              />
            </el-form-item>
            积分抵扣
            <el-form-item>
              <el-input
                v-model="info.deduct_money"
                placeholder="请输入"
                style="width: 90%; margin: 0 5px"
              />
            </el-form-item>
            元
          </div>
        </el-form-item>
        <el-form-item label="积分有效期限">
          <el-radio-group v-model="info.deadline_type">
            <el-radio :label="10">永久有效</el-radio>
            <el-radio :label="20">
              有效期
              <span v-if="info.deadline_type && info.deadline_type === 20">
                <el-input
                  placeholder="请输入"
                  style="width: 50%; margin: 0 5px"
                  v-model="info.deadline"
                />个月过期
                <span style="color: #ec561f">注：次月月底过期</span></span
              >
            </el-radio>
            <el-radio :label="30" style="margin-left: 10px"
              >指定日期
              <span v-if="info.deadline_type && info.deadline_type === 30">
                <el-date-picker
                  v-model="info.deadlineDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="退货退还积分">
          <el-radio-group v-model="info.sales_return_integral">
            <el-radio :label="10">关闭</el-radio>
            <el-radio :label="20">开启</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="create">
        <div style="margin: 15px auto 0 auto;text-align: center display: flex">
          <el-button
            type="primary"
            plain
            v-if="
              speed === '会员等级设置' ||
              speed === '积分获取规则' ||
              speed === '积分抵用规则'
            "
            @click="lastStep('info')"
            >上一步</el-button
          >
          <el-button
            type="primary"
            v-if="
              speed === '基本信息' ||
              speed === '会员等级设置' ||
              speed === '积分获取规则'
            "
            @click="nextStep('info')"
            >下一步</el-button
          >
          <el-button
            type="primary"
            :loading="finishLoading"
            v-if="speed === '积分抵用规则'"
            @click="finishSet('info')"
            >完成</el-button
          >
        </div>
      </div>
    </el-form>
    <el-dialog
      title="新增会员等级"
      :close-on-click-modal="false"
      :visible.sync="levelVisiable"
    >
      <div style="margin-top: -30px">
        <div class="title" style="font-weight: 600">
          <div class="span"></div>
          等级基本信息
        </div>
        <el-form :rules="level_rules" ref="level_rules" :model="level_Info">
          <el-form-item label="等级名称" label-width="120px" prop="level_name">
            <el-input
              v-model="level_Info.level_name"
              autocomplete="off"
              style="width: 40%"
              placeholder="请输入等级名称"
              maxlength="10"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="会员等级图片" label-width="120px" required>
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="handleImgSuccess"
            >
              <img v-if="level_img" :src="level_img" class="avatarOther" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div>选择会员等级图片</div>
            <div class="levelList">
              <div
                class="level"
                :class="level_index === item.index ? 'selectLevel' : null"
                v-for="(item, index) in levelImgList"
                :key="index"
                @click="handleLevel(item)"
              >
                <div class="img">
                  <img :src="item.icon" />
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="累计消费金额"
            label-width="120px"
            prop="sales_amount"
          >
            <el-input
              v-model="level_Info.sales_amount"
              autocomplete="off"
              style="width: 40%"
              placeholder="请输入累计消费金额"
            />
            <div style="font-size: 12px; color: #909399">
              累计的消费金额达到设置金额，自动成为该等级会员
            </div>
          </el-form-item>
        </el-form>
        <div style="height: 1px; background: #e8e8e8"></div>
        <div class="title" style="font-weight: 600">
          <div class="span"></div>
          等级折扣
        </div>
        <div style="font-size: 12px; color: #909399; margin: -15px 0 0 25px">
          设置会员各品类可享的折扣，可填范围0.1-10
        </div>
        <div class="scroll">
          <el-form>
            <el-tree
              class="tree-line"
              :data="attrLevelList"
              node-key="goods_type_id"
              :default-expanded-keys="expandedKeys"
              :expand-on-click-node="false"
            >
              <div slot-scope="{ node, data }">
                <div>
                  {{ node.label }}
                  <template
                    v-for="(item, index) in client_system_level_discount"
                  >
                    <span
                      :key="index"
                      v-if="data.goods_type_id === item.goods_type_id"
                    >
                      <el-input
                        v-model="item.discount"
                        style="
                          height: 36px;
                          width: 40%;
                          margin: 5px 5px 5px 5px;
                        "
                        @input="handleCheck($event)"
                      />折
                    </span></template
                  >
                </div>
              </div>
            </el-tree>
          </el-form>
        </div>
        <div style="text-align: right; margin-top: 10px">
          <el-button type="primary" @click="submit('level_rules')"
            >确定</el-button
          >
          <el-button @click="cancelLevel">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import storage from "good-storage";
import { getAllGoodsType } from "@/api/goods/goodsConfig/classify";
import { create } from "@/api/member/system/Index.js";

export default {
  data() {
    return {
      speed: "基本信息",
      info: {},
      rules: {
        title: [
          {
            required: true,
            message: "请完善会员体系名称",
            trigger: "blur",
          },
        ],
        use_integral_unit: [
          {
            required: true,
            message: "请完善积分",
            trigger: "blur",
          },
        ],
        deduct_money: [
          {
            required: true,
            message: "请完善积分抵扣的金额",
            trigger: "blur",
          },
        ],
        deadline_type: [
          {
            required: true,
            message: "请完善积分有效期限",
            trigger: "change",
          },
        ],
        sales_return_integral: [
          {
            required: true,
            message: "请完善退货退还积分",
            trigger: "change",
          },
        ],
      },
      levelVisiable: false,
      level_Info: {},
      client_system_level: [],
      client_system_level_index: null,
      level_index: 0,
      level_image: "",
      level_rules: {
        level_name: [
          {
            required: true,
            message: "请完善等级名称",
            trigger: "blur",
          },
        ],
        sales_amount: [
          {
            required: true,
            message: "请完善累计消费金额",
            trigger: "blur",
          },
        ],
      },
      token: "",
      level_img: "",
      attrList: [],
      expandedKeys: [],
      finishLoading: false,
      client_system_level_discount: [],
      client_system_get_inegral: [],
      attrLevelList: [],
      levelImgList: [
        {
          index: 1,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/28a704e001609bb3f5721b28b31fc0a918139fca.png",
        },
        {
          index: 2,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/25ef5089505c02a1d206933ee489f88f01a1f8c0.png",
        },
        {
          index: 3,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/6f495b515812bba2cc91118246614e9291707dba.png",
        },
        {
          index: 4,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/9357e5cb49abfa07be7e55012c950ce2ed1fd604.png",
        },
        {
          index: 5,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/b4566921d20757da16b6b5ea395166bdf97643c9.png",
        },
        {
          index: 6,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/e9abca2e866c2d8068f364fecbb0e4caad9176f3.png",
        },
        {
          index: 7,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/058b6b2e840dc34d97632349e6d000c83b70375e.png",
        },
        {
          index: 8,
          icon: "http://etouch-edianbao.oss-cn-shenzhen.aliyuncs.com/edianbao/agents/202011/5122d27f2828c21850fc3fb0cfd063cc8ac248a0.png",
        },
      ],
      levelType: "add",
    };
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
    this.getGoodsTypeList();
    this.getLevelGoodsTypeList();
  },
  methods: {
    /*
     *  上一步
     */
    lastStep() {
      switch (this.speed) {
        case "会员等级设置":
          this.speed = "基本信息";
          break;
        case "积分获取规则":
          this.speed = "会员等级设置";
          break;
        case "积分抵用规则":
          this.speed = "积分获取规则";
          break;
        default:
          break;
      }
    },
    /*
     *  下一步
     */
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (this.speed) {
            case "基本信息":
              this.speed = "会员等级设置";
              break;
            case "会员等级设置":
              if (this.client_system_level.length === 0) {
                this.$message({
                  message: "请完善会员等级",
                  type: "warning",
                });
              } else {
                this.speed = "积分获取规则";
              }

              break;
            case "积分获取规则":
              this.speed = "积分抵用规则";
              break;
            default:
              break;
          }
        }
      });
    },
    /*
     *  完成
     */
    finishSet(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.finishLoading = true;
          let client_system_level = this.client_system_level.map((item) => {
            item.client_system_level_discount =
              item.client_system_level_discount.filter((items) => {
                if (items.discount) {
                  return items;
                }
              });
            return item;
          });
          let client_system_get_inegral = this.client_system_get_inegral.map(
            (item) => {
              if (!item.integral_type) {
                item.integral_type = "10";
              }
              return item;
            }
          );

          const data = {
            title: this.info.title,
            remark: this.info.remark,
            use_integral_unit: this.info.use_integral_unit,
            deduct_money: this.info.deduct_money,
            deadline_type: this.info.deadline_type,
            deadline:
              this.info.deadline_type === 30
                ? this.info.deadlineDate
                : this.info.deadline || 0,
            sales_return_integral: this.info.sales_return_integral,
            client_system_level: JSON.stringify(client_system_level),
            client_system_get_inegral: JSON.stringify(
              client_system_get_inegral
            ),
          };
          create(data)
            .then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                this.client_system_level = [];
                this.client_system_get_inegral = [];
                this.info = {};
                this.finishLoading = false;
                this.goBack();
              } else {
                this.finishLoading = false;
              }
            })
            .catch((res) => {
              this.finishLoading = false;
            });
        }
      });
    },
    /*
     * 折扣输入校验
     */
    handleCheck(event) {
      if (event) {
        console.log(/^(\-)?\d+(\.\d{1,2})$/.test(event));
        // if (!/^[0-9]+(\.[0-9]{1,3})?$/.test(event)) {
        //   this.$message({
        //     message: "请输入正整数，最多一位小数",
        //     type: "error",
        //   });
        // }
      }
    },
    /*
     * 返回列表
     */
    goBack() {
      this.$emit("hide");
    },
    /*
     *  上传会员等级图片
     */
    handleImgSuccess(res, file) {
      this.level_image = res.data;
      this.level_img = URL.createObjectURL(file.raw);
    },
    /*
     *  获取选择等级图片
     */
    handleLevel(item) {
      this.level_img = "";
      this.level_image = item.icon;
      this.level_index = item.index;
    },
    /*
     *  会员等级图片上传限制
     */
    beforeAvatarUpload(file) {
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isPNG) {
        this.$message.error("上传等级图片只能是 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isPNG && isLt2M;
    },
    /*
     *   获取品类下拉数据
     */
    getGoodsTypeList() {
      getAllGoodsType().then((res) => {
        if (res.code === 1) {
          this.handleArr(res.data, 1);
          this.attrList = res.data;
        }
      });
    },
    getLevelGoodsTypeList() {
      getAllGoodsType().then((res) => {
        if (res.code === 1) {
          this.handleArr(res.data, 2);
          this.attrLevelList = res.data;
        }
      });
    },
    /*
     *  新增会员等级
     */
    handleAddLevel() {
      this.levelType = "add";
      this.levelVisiable = true;
      this.client_system_level_discount = [];
      this.getLevelGoodsTypeList();
    },
    handleArr(arr, type) {
      arr.forEach((item) => {
        this.expandedKeys.push(item.goods_type_id);
        this.client_system_level_discount.push({
          goods_type_id: item.goods_type_id,
          discount: null,
        });
        if (type === 1) {
          this.client_system_get_inegral.push({
            goods_type_id: item.goods_type_id,
            goods_type_name: item.goods_type_name,
            integral_type: null,
            integral_unit: null,
            integral_proportion: null,
            sales_type: item.sales_type,
          });
        }

        item.label = item.goods_type_name;
        if (item.children) {
          this.handleArr(item.children);
        }
      });
    },
    /*
     *  会员等级数据保存
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = this.client_system_level;
          let nameRepeat = false; // 等级名称重复标识
          let amountRepeat = false; // 累计消费金额重复标识

          if (arr.length !== 0) {
            arr.forEach((item, index) => {
              if (
                item.level_name === this.level_Info.level_name &&
                index !== this.client_system_level_index
              ) {
                nameRepeat = true;
              }

              if (
                item.sales_amount === this.level_Info.sales_amount &&
                index !== this.client_system_level_index
              ) {
                amountRepeat = true;
              }
            });
          }

          if (this.level_image === "") {
            this.$message({
              type: "error",
              message: "请选择或者上传会员等级图片",
            });
            return;
          }

          if (nameRepeat) {
            this.$message({
              type: "error",
              message: "等级名称重复，请重新修改",
            });
            nameRepeat = false;
            return;
          }
          if (amountRepeat) {
            this.$message({
              type: "error",
              message: "累计消费金额重复，请重新修改",
            });
            amountRepeat = false;
            return;
          }

          let data = {
            level_name: this.level_Info.level_name,
            sales_amount: this.level_Info.sales_amount,
            level_image: this.level_image,
            client_system_level_discount: JSON.parse(
              JSON.stringify(this.client_system_level_discount)
            ),
          };
          if (arr.length === 0) {
            arr.push({ ...data });
          } else {
            let isEdit = false;
            arr.forEach((item, index) => {
              if (this.client_system_level_index === index) {
                isEdit = true;
              }
            });
            if (isEdit) {
              arr[this.client_system_level_index] = data;
            } else {
              arr.push({ ...data });
            }
          }
          this.client_system_level = JSON.parse(JSON.stringify(arr));
          this.$set(this.client_system_level);
          this.cancelLevel();
        }
      });
    },
    /*
     *  关闭等级弹窗
     */
    cancelLevel() {
      this.level_Info = {};
      this.level_image = "";
      this.level_img = "";
      this.level_index = 0;
      this.levelVisiable = false;
      this.client_system_level_discount = [];
      this.client_system_level_index = null;
    },
    /*
     *  编辑会员等级信息
     */
    handleEditLevelInfo(info, index) {
      this.levelType = "edit";
      this.client_system_level_index = index;
      this.level_Info = JSON.parse(JSON.stringify(info));
      this.level_image = info.level_image;
      this.level_img = info.level_image;
      this.client_system_level_discount = info.client_system_level_discount;
      this.levelImgList.forEach((item) => {
        if (item.icon === info.level_image) {
          this.level_index = item.index;
        }
      });
      this.levelVisiable = true;
    },
    /*
     *  删除会员等级信息
     */
    handleDelLevelInfo(info, index) {
      this.$confirm("此操作将删除该等级, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.client_system_level.splice(
          this.client_system_level.indexOf(info),
          1
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.scroll {
  height: calc(100vh - 400px);
  overflow: auto;
}
.create {
  height: 64px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 70px;
  text-align: center;
  background-color: white;
  box-shadow: 0px -1px 3px 1px rgba(153, 153, 153, 0.1);
}
/deep/ .el-tree-node__label {
  font-size: 14px;
  &:nth-child(1) {
    font-weight: bold !important;
  }
}
/deep/ .el-tree-node__content {
  height: 50px;
  line-height: 50px;
}
.speed {
  display: flex;
  height: 58px;
  background: white;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 7px 7px;
  line-height: 58px;
  font-size: 16px;

  .li {
    width: 25%;
    text-align: center;
    color: #909399;
    img {
      margin-right: 5px;
      margin-top: -3px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    i {
      padding: 6px;
      float: right;
      margin-top: 22px;
      border: solid#c3c3c3;
      border-width: 0 1px 1px 0;
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 400;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.avatar-uploader .el-upload {
  width: 70px;
  height: 70px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.avatar {
  width: 290px;
  height: 170px;
  display: block;
}
.avatarOther {
  width: 70px;
  height: 70px;
  display: block;
}
.levelList {
  display: flex;
  flex-wrap: wrap;
  .level {
    margin-right: 10px;
    width: 80px;
    height: 80px;
    background: url("../../../../assets/images/member/icon_unchecked.png");
    background-size: 100% 100%;

    .img {
      margin: 29px auto 0 12px;
    }
  }
  .selectLevel {
    background: url("../../../../assets/images/member/icon_checked.png") !important;
  }
  .level:hover {
    cursor: pointer;
  }
}
.levelImg {
  width: 80px;
  height: 30px;

  img {
    width: 80px;
    height: 30px;
  }
}
</style>