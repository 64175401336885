<template>
  <div>
    <div class="content" v-if="!isAdd && !isEdit && !isDetail">
      <div class="card">
        <div class="left">
          <el-button type="primary" @click="handleAdd">创建会员体系</el-button>
        </div>
      </div>
      <div class="table" style="border-top: none">
        <el-table :data="list" stripe height="720">
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            label="会员体系名称"
            prop="title"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作人"
            prop="store_user_name"
            align="center"
          ></el-table-column>
          <el-table-column
            label="最后修改时间"
            prop="update_time"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            prop="action"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option label="编辑" :value="2"></el-option>
                  <el-option label="删除" :value="3"></el-option>
                </el-select>
              </div> </template
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          :page-sizes="[15, 30, 40, 50]"
          :page-size="15"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <Add v-if="isAdd" @hide="getList" />
    <Edit v-if="isEdit" @hide="getList" :client_system_id="client_system_id" />
    <Detail
      v-if="isDetail"
      @hide="getList"
      :client_system_id="client_system_id"
    />
  </div>
</template>

<script>
import { getList, deleteSys } from "@/api/member/system/Index.js";
import Add from "./Add.vue";
import Edit from "./Edit.vue";
import Detail from "./Detail.vue";
export default {
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      isAdd: false,
      isEdit: false,
      isDetail: false,
      client_system_id: 0,
    };
  },
  components: {
    Add,
    Edit,
    Detail,
  },
  created() {
    this.getList();
  },
  methods: {
    /*
     *  获取列表数据
     */
    getList() {
      this.isDetail = false;
      this.isEdit = false;
      this.isAdd = false;
      getList({ page: this.page }).then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
        }
      });
    },
    /*
     *  创建会员体系
     */
    handleAdd() {
      this.isAdd = true;
    },
    /*
     *  操作
     */
    handleDetails(val, info) {
      this.client_system_id = info.client_system_id;
      if (val === 1) {
        this.isDetail = true;
      }
      if (val === 2) {
        this.isEdit = true;
      }
      if (val === 3) {
        this.$confirm("此操作将删除该会员体系, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteSys({ client_system_id: info.client_system_id })
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "删除成功",
                  });
                  this.getList();
                } else {
                  this.$message({
                    message: res.msg,
                    type: "error",
                  });
                  this.getList();
                }
              })
              .catch(() => {
               
                this.getList();
              });
          })
          .catch(() => {
            this.$message({
              message: "取消删除",
              type: "info",
            });
          });
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange() {},
    /*
     *  页数操作
     */
    handleSizeChange() {},
  },
};
</script>

<style lang="less" scoped>
</style>