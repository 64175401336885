<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="会员体系详情"> </el-page-header>
      </div>
    </div>
    <div
      class="content"
      style="
        border: 1px solid #e8e8e8;
        margin-top: 10px;
        padding-bottom: 15px;
        border-radius: 7px;
      "
    >
      <div class="title">
        <div class="span"></div>
        基本信息
      </div>
      <div style="display: flex">
        <div class="boxLine">
          <div class="left">会员体系名称</div>
          <div class="right">{{ info.title }}</div>
        </div>
        <div class="boxLine">
          <div class="left">备注</div>
          <div class="right">{{ info.remark || "--" }}</div>
        </div>
        <div class="boxLine">
          <div class="left">操作人</div>
          <div class="right">{{ info.store_user_name }}</div>
        </div>
        <div class="boxLine">
          <div class="left">创建时间</div>
          <div class="right">{{ info.create_time }}</div>
        </div>
        <div class="boxLine">
          <div class="left">最后修改时间</div>
          <div class="right">{{ info.update_time }}</div>
        </div>
      </div>
    </div>
    <div
      class="content"
      style="
        border: 1px solid #e8e8e8;
        margin-top: 10px;
        margin-bottom: 100px;
        padding: 15px 0 15px 0;
        border-radius: 7px;
      "
    >
      <el-tabs
        v-model="activeName"
        @tab-click="handleTab()"
        style="margin: 0 15px"
      >
        <el-tab-pane label="会员等级设置" name="first">
          <div class="table" style="border: 1px solid #e8e8e8">
            <el-table
              ref="plTable"
              :data="client_system_level"
              key="goods_type_id"
              stripe
              height="320"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="200"
              ></el-table-column>
              <el-table-column label="等级图标" prop="name" align="center">
                <template slot-scope="scope">
                  <img :src="scope.row.level_image" class="levelImg" />
                </template>
              </el-table-column>
              <el-table-column
                label="等级名称"
                prop="level_name"
                align="center"
              ></el-table-column>
              <el-table-column
                label="累计消费金额"
                prop="sales_amount"
                align="center"
              ></el-table-column>
              <el-table-column
                label="操作"
                align="center"
                prop="action"
                width="180"
              >
                <template slot-scope="scope">
                  <div>
                    <el-button
                      type="text"
                      @click="handleInfo(scope.row, scope.$index)"
                      >详情</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分获取规则" name="second">
          <div class="table" style="border-top: 1px solid #e8e8e8">
            <el-table :data="client_system_get_inegral" stripe height="520">
              <el-table-column
                label="品类"
                prop="goods_type_name"
                align="center"
              />
              <el-table-column
                label="积分获取规则"
                prop="action"
                align="center"
              >
                <template slot-scope="scope">
                  {{ integral_type[scope.row.integral_type] }}
                  <span v-if="scope.row.integral_type !== 10">
                    每{{ scope.row.integral_unit
                    }}{{
                      scope.row.integral_type === 40 ||
                      scope.row.integral_type === 50
                        ? "克"
                        : "元"
                    }}可获得{{ scope.row.integral_proportion }}积分
                  </span>
                </template>
              </el-table-column></el-table
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="积分抵用规则" name="third">
          <div>
            <div class="levelBox">
              <div class="left">积分使用汇率</div>
              <div>
                每{{ info.use_integral_unit }}积分抵扣{{ info.deduct_money }}元
              </div>
            </div>
            <div class="levelBox">
              <div class="left">积分有效期限</div>
              <div>
                {{
                  info.deadline === 0
                    ? "永久有限"
                    : info.deadline_type === 20
                    ? info.deadline + "个月过期"
                    : info.deadline + "过期"
                }}
              </div>
            </div>
            <div class="levelBox">
              <div class="left">退货退还积分</div>
              <div>
                {{ info.sales_return_integral === 10 ? "关闭" : "开启" }}
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      title="会员等级详情"
      :close-on-click-modal="false"
      :visible.sync="levelVisiable"
    >
      <div style="margin-top: -30px">
        <div class="title" style="font-weight: 600">
          <div class="span"></div>
          等级基本信息
        </div>
        <div class="levelBox">
          <div class="left">等级名称</div>
          <div>{{ levelInfo.level_name }}</div>
        </div>
        <div class="levelBox">
          <div class="left">会员等级图片</div>
          <div><img :src="levelInfo.level_image" /></div>
        </div>
        <div class="levelBox">
          <div class="left">累计消费金额</div>
          <div>{{ levelInfo.sales_amount }}</div>
        </div>
        <div style="height: 1px; background: #e8e8e8; margin-top: 5px"></div>
        <div class="title" style="font-weight: 600">
          <div class="span"></div>
          等级折扣
        </div>
        <div class="table" style="border-top: 1px solid #e8e8e8">
          <el-table :data="levelInfo.client_system_level_discount" stripe>
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="180"
            ></el-table-column>
            <el-table-column
              label="品类"
              align="center"
              prop="goods_type_name"
            ></el-table-column>
            <el-table-column label="折扣" align="center" prop="discount">
              <template slot-scope="scope">
                {{ scope.row.discount }}折
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { detail } from "@/api/member/system/Index.js";
export default {
  data() {
    return {
      info: {},
      client_system_get_inegral: [],
      client_system_level: [],
      activeName: "first",
      levelVisiable: false,
      levelInfo: {},
      integral_type: {
        10: "不设置",
        20: "按每件货标签价",
        30: "按每件货实付金额",
        40: "按每件货克重",
        50: "按每件货净金重",
      },
    };
  },
  props: {
    client_system_id: Number,
  },
  created() {
    this.getDetails();
  },
  methods: {
    /*
     *  获取详情
     */
    getDetails() {
      detail({ client_system_id: this.client_system_id }).then((res) => {
        if (res.code === 1) {
          this.info = {
            ...res.data,
            deadlineStatus: res.data.deadline === 0 ? 0 : 1,
          };
          this.client_system_get_inegral = res.data.client_system_get_inegral;
          this.client_system_level = res.data.client_system_level;
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    handleInfo(row) {
      this.levelInfo = row;
      this.levelVisiable = true;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  font-weight: 400;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.boxLine {
  display: flex;
  padding-left: 50px;
  width: 20%;
  line-height: 40px;
  .left {
    position: relative;
    width: 120px;
    color: #909399;
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #2791ff;
      position: absolute;
      top: 18px;
      left: -10px;
    }
  }
}
.levelBox {
  padding-left: 30px;
  display: flex;
  line-height: 40px;
  .left {
    position: relative;
    width: 120px;
    color: #909399;
  }
}
.levelImg {
  width: 80px;
  height: 30px;

  img {
    width: 80px;
    height: 30px;
  }
}
</style>